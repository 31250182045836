import Choices from "choices.js";

export function selectFunctions() {
  const selects = document.querySelectorAll('[data-select] select');
  selects.forEach(select => new Choices(select, {
    closeDropdownOnSelect: true,
    itemSelectText: 'Auswählen',
    removeItemButton: true,
    searchResultLimit: -1,
    shouldSort: false,
  }));
}
