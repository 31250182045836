import { externalLinks } from "./components/external-links";
import { generalFunctions } from "./components/general-functions";
import { bootstrapTooltips } from "./components/bootstrap-tooltips";
import { bootstrapToasts } from "./components/bootstrap-toasts";
import { selectFunctions } from "./components/select-choices";

generalFunctions();

document.addEventListener('DOMContentLoaded', () => {
  /* eslint-disable no-new, no-restricted-syntax */
  externalLinks();
  bootstrapTooltips();
  bootstrapToasts();
  selectFunctions();
});
