export function bootstrapToasts() {
  // const toastElList = document.querySelectorAll('.toast')
  // const toastList = [...toastElList].map(toastEl => new bootstrap.Toast(toastEl, option))
  // toastList.forEach(toast => toast.show())

  window.onload = (event) => {
    let toastElList = document.querySelectorAll('.toast')[0];
    if (toastElList) {
      let bsAlert = new bootstrap.Toast(toastElList);
      bsAlert.show();
    }
  }
}
